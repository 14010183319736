import {
    AdjustmentsVerticalIcon,
    ArrowDownOnSquareStackIcon,
    ArrowRightOnRectangleIcon,
    ArrowTopRightOnSquareIcon,
    AtSymbolIcon,
    Bars3CenterLeftIcon,
    Bars4Icon,
    BoltIcon,
    BriefcaseIcon,
    BuildingOffice2Icon,
    ChartBarIcon,
    ChatBubbleLeftEllipsisIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    CheckBadgeIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    CircleStackIcon,
    ClockIcon,
    CodeBracketIcon,
    CogIcon,
    CurrencyDollarIcon,
    CursorArrowRaysIcon,
    ComputerDesktopIcon,
    DocumentMagnifyingGlassIcon,
    DocumentTextIcon,
    EnvelopeIcon,
    FlagIcon,
    GiftIcon,
    GlobeEuropeAfricaIcon,
    HomeIcon,
    IdentificationIcon,
    LanguageIcon,
    LifebuoyIcon,
    LightBulbIcon,
    LinkIcon,
    ListBulletIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MapPinIcon,
    NewspaperIcon,
    PaperClipIcon,
    PencilSquareIcon,
    PhoneIcon,
    PhotoIcon,
    QuestionMarkCircleIcon,
    ReceiptPercentIcon,
    RectangleGroupIcon,
    ScaleIcon,
    SignalSlashIcon,
    Square2StackIcon,
    TableCellsIcon,
    TagIcon,
    TicketIcon,
    TrashIcon,
    UserCircleIcon,
    UserGroupIcon,
    XMarkIcon,
    PlayIcon,
} from "@heroicons/react/24/outline";

/**
 * Mapping between icon name and Icon component.
 */
OutlineIcon.map = {
    "briefcase": BriefcaseIcon,
    "chart-bar": ChartBarIcon,
    "chat-bubble-oval-left-ellipsis": ChatBubbleOvalLeftEllipsisIcon,
    "cog": CogIcon,
    "circle-stack": CircleStackIcon,
    "document-magnifying-glass": DocumentMagnifyingGlassIcon,
    "home": HomeIcon,
    "question-mark-circle": QuestionMarkCircleIcon,
    "user-circle": UserCircleIcon,
    "x": XMarkIcon,
    "chevron-up": ChevronUpIcon,
    "scale": ScaleIcon,
    "chevron-down": ChevronDownIcon,
    "chevron-left": ChevronLeftIcon,
    "chevron-right": ChevronRightIcon,
    "support": LifebuoyIcon,
    "identification": IdentificationIcon,
    "user-group": UserGroupIcon,
    "template": RectangleGroupIcon,
    "newspaper": NewspaperIcon,
    "adjustments": AdjustmentsVerticalIcon,
    "online": GlobeEuropeAfricaIcon,
    "offline": SignalSlashIcon,
    "draft": PencilSquareIcon,
    "trash": TrashIcon,
    "logout": ArrowRightOnRectangleIcon,
    "bars-2-center-left": Bars3CenterLeftIcon,
    "photo": PhotoIcon,
    "document-text": DocumentTextIcon,
    "receipt-percent": ReceiptPercentIcon,
    "ticket": TicketIcon,
    "table-cells": TableCellsIcon,
    "map": MapIcon,
    "envelope": EnvelopeIcon,
    "map-pin": MapPinIcon,
    "link": LinkIcon,
    "bolt": BoltIcon,
    "light-bulb": LightBulbIcon,
    "phone": PhoneIcon,
    "paper-clip": PaperClipIcon,
    "gift": GiftIcon,
    "flag": FlagIcon,
    "arrow-top-right-on-square": ArrowTopRightOnSquareIcon,
    "duplicate": Square2StackIcon,
    "at-symbol": AtSymbolIcon,
    "check": CheckIcon,
    "tag": TagIcon,
    "cursor-arrow-rays": CursorArrowRaysIcon,
    "clock": ClockIcon,
    "chat-bubble-left-ellipsis": ChatBubbleLeftEllipsisIcon,
    "check-badge": CheckBadgeIcon,
    "arrow-down-on-square-stack": ArrowDownOnSquareStackIcon,
    "building-office-2": BuildingOffice2Icon,
    "currency-dollar": CurrencyDollarIcon,
    "code-bracket": CodeBracketIcon,
    "bars-4": Bars4Icon,
    "list-bullet": ListBulletIcon,
    "magnifying-glass-circle": MagnifyingGlassCircleIcon,
    "language": LanguageIcon,
    "play": PlayIcon,
    "computer-desktop": ComputerDesktopIcon,
};

export type TOutlineIconName = keyof typeof OutlineIcon.map;
export interface IOutlineIconProps {
    [key: string]: any;
    name: TOutlineIconName;
}

export function OutlineIcon(props: IOutlineIconProps) {
    const { name, ...iconProps } = props;
    const hasIcon = name in OutlineIcon.map;
    const Icon = OutlineIcon.map[name];

    if (!hasIcon) {
        throw Error(`Invalid icon name: ${name}`);
    }

    return <Icon {...iconProps} />;
}
